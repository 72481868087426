import { useState } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import Button from "../../components/button";
import Modal from "../../components/modal";
import Spinner from "../../components/spinner";
import logoKraft from "../../assets/images/logoKraft.svg";
import logosMarcas from "../../assets/images/logos-marcas.svg";

export default function Home() {
  const [prizesAvailable, setPrizesAvailable] = useState(100);
  const [loading, setLoading] = useState(false);
  const [lastSyncDate, setLastSyncDate] = useState(
    localStorage.getItem("lastSyncDate")
  );

  const getPrizes = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/prizes?filter={"order": "order ASC", "where":{"companyId":"${process.env.REACT_APP_COMPANY_ID}"}}`
    );
    const data = await response.json();
    return data;
  };

  const postPlayer = async (player, allPlayersPrizes) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/players`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: player.name,
        companyId: parseInt(process.env.REACT_APP_COMPANY_ID),
        additionalData: JSON.stringify({
          email: player.email,
          phone: player.phone,
          businessType: player.businessType,
          otherBusinessType: player.otherBusinessType,
          receiveInfo: player.receiveInfo,
        }),
      }),
    });
    const newPlayer = await response.json();
    const playerPrizes = allPlayersPrizes.filter(
      (playerPrize) => playerPrize.playerId === player.id
    );
    for (const playerPrize of playerPrizes) {
      await fetch(`${process.env.REACT_APP_API_URL}/players-prizes`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prizeId: playerPrize.prizeId,
          playerId: newPlayer.id,
        }),
      });
    }
  };

  const sync = async () => {
    setLoading(true);
    const prizes = await getPrizes();
    localStorage.setItem("prizes", JSON.stringify(prizes));

    const players = localStorage.getItem("players");
    const playersPrizes = localStorage.getItem("playersPrizes");
    if (players && playersPrizes) {
      const playersInLocalStorage = JSON.parse(players);
      const playersPrizesInLocalStorage = JSON.parse(playersPrizes);

      for (const player of playersInLocalStorage) {
        await postPlayer(player, playersPrizesInLocalStorage);
      }

      localStorage.removeItem("players");
      localStorage.removeItem("playersPrizes");
    }

    const currentQuantities = _.map(prizes, "currentQty");
    const prizesAvailable = _.sum(currentQuantities);
    setPrizesAvailable(prizesAvailable);

    setLastSyncDate(new Date());
    localStorage.setItem("lastSyncDate", new Date());

    setLoading(false);
  };

  return (
    <div className="min-h-screen bg-fondoPrincipal bg-cover bg-no-repeat flex items-center justify-center landscape:px-8 gap-4">
      {loading && <Spinner />}
      <div className="portrait: hidden" />
      <div className="bg-fondoMadera bg-cover bg-no-repeat bg-center portrait:w-full landscape:w-3/5 flex flex-col min-h-screen justify-center items-center gap-8">
        <div>
          <h1 className="text-white font-myriad-pro font-bold text-6xl">Disfrutá con</h1>
        </div>
        <img className="w-4/5 mb-4" src={logoKraft} alt="Logo Kraft" />
        {lastSyncDate && (
          <div>
            {prizesAvailable > 0 ? (
              <Link to="/form">
                <Button label={"Jugá con nosotros"} />
              </Link>
            ) : (
              <Modal>
                <div className="flex flex-col items-center justify-center gap-4">
                  <h1 className="text-2xl font-bold text-center text-white">
                    Lo sentimos. Ya no hay premios disponibles.
                  </h1>
                </div>
              </Modal>
            )}
          </div>
        )}
        {navigator.onLine && (
          <div className="text-center">
            <Button onClick={sync} label={"Sincronizar"} />
            <p className="text-white mt-2">
              {lastSyncDate
                ? `Sincronizado el ${new Intl.DateTimeFormat("es", {
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "numeric",
                  }).format(new Date(lastSyncDate))}`
                : "No se ha sincronizado el sistema"}
            </p>
          </div>
        )}
        <img className="w-4/6 mt-8" src={logosMarcas} alt="Logos" />
      </div>
      <div className="portrait: hidden" />
    </div>
  );
}
