import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../../components/modal";
import RoulleteComponent from "../../components/roulette";
import Spinner from "../../components/spinner";
import logoKraft from "../../assets/images/logoKraft.svg";
import heinz from "../../assets/images/heinz.svg";
import emily from "../../assets/images/emily.svg";
import banquete from "../../assets/images/banquete.svg";
import kraft from "../../assets/images/kraft.svg";
import a1 from "../../assets/images/a1.svg";
import lea from "../../assets/images/lea.svg";
import footerRuelta from "../../assets/images/footer-ruleta.svg";

export default function Roullete() {
  const [loading, setLoading] = useState(false);
  const [userPrize, setPrize] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [prizes, setPrizes] = useState([]);

  const { playerId } = useParams();

  useEffect(() => {
    const prizes = localStorage.getItem("prizes");
    setPrizes(JSON.parse(prizes));
  }, []);

  const navigate = useNavigate();

  const setAndShowPrize = async (newPrize) => {
    if (newPrize) {
      setPrize(newPrize);
      setLoading(true);
      const playersPrizes = localStorage.getItem("playersPrizes");
      if (playersPrizes) {
        const playersPrizesArray = JSON.parse(playersPrizes);
        const newPlayerPrize = {
          playerId: parseInt(playerId),
          prizeId: newPrize.id,
        };
        localStorage.setItem(
          "playersPrizes",
          JSON.stringify([...playersPrizesArray, newPlayerPrize])
        );
      } else {
        localStorage.setItem(
          "playersPrizes",
          JSON.stringify([
            {
              playerId: parseInt(playerId),
              prizeId: newPrize.id,
            },
          ])
        );
      }
      const prizes = localStorage.getItem("prizes");
      const prizesArray = JSON.parse(prizes);
      const prizeIndex = prizesArray.findIndex(
        (prize) => prize.id === newPrize.id
      );
      prizesArray[prizeIndex].currentQty =
        prizesArray[prizeIndex].currentQty - 1;
      localStorage.setItem("prizes", JSON.stringify(prizesArray));
      setLoading(false);
      setOpenModal(true);
    }
  };

  return (
    <div className="max-h-screen min-h-screen overflow-hidden">
      <div className="bg-white bg-cover bg-no-repeat flex items-center justify-center">
        {loading && <Spinner />}
        {openModal && (
          <Modal
            onClose={() => {
              navigate("/");
            }}
          >
            <div className="py-28 w-full flex justify-center">
              <div className="text-center">
                <h1 className="text-primary font-bold portrait:text-3xl landscape:text-5xl">
                  {userPrize.name}
                </h1>
              </div>
            </div>
          </Modal>
        )}
        <div className="portrait:hidden flex flex-col items-center justify-center gap-16 px-8">
          <img className="w-1/2" src={heinz} alt="Heinz" />
          <img className="w-1/2" src={emily} alt="Emily" />
          <img className="w-1/2" src={banquete} alt="Banquete" />
        </div>
        <div className="bg-fondoMadera rounded-b-full bg-cover bg-no-repeat bg-center portrait:w-full landscape:w-1/2 flex flex-col justify-center items-center portrait:px-4 landscape:px-8 z-10  min-h-[90vh]">
          <img className="w-1/2 z-40 mb-8" src={logoKraft} alt="Kraft" />
          <div className="portrait:w-8/12 landscape:w-10/12">
            <RoulleteComponent
              start_callback={(prize) => {
                setAndShowPrize(prize);
              }}
              prize_arr={prizes}
              start_text={"GIRAR"}
              reset_text={"REINICIAR"}
              has_reset={false}
            />
          </div>
        </div>
        <div className="portrait:hidden flex flex-col items-center justify-center gap-16 px-8">
          <img className="w-1/2" src={kraft} alt="Kraft" />
          <img className="w-1/4" src={a1} alt="A1" />
          <img className="w-1/2" src={lea} alt="Lea" />
        </div>
      </div>
      <div className="bg-footerRuleta w-full h-32 fixed bottom-0 z-0" />
    </div>
  );
}
