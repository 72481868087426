import React, { useRef, useEffect, useState } from "react";
import { TweenLite, TweenMax, Power2 } from "gsap";
import _ from "lodash";
import Button from "./button";

const Roulette = ({
  reset_callback = () => {},
  start_callback = () => {},
  has_reset = true,
  start_text = "Start!",
  reset_text = "Reset",
  ...props
}) => {
  const [start, set_start] = useState(false);
  const [show_reset, set_show_reset] = useState(false);
  const [forbid_start, set_forbid_start] = useState(false);

  const clickToReset = () => {
    set_start(false);
    reset_callback();
    set_show_reset(false);
    set_forbid_start(true);
  };
  const clickToTrigger = () => {
    set_start(true);
    start_callback();
  };

  return (
    <div className="flex w-full h-full flex-col">
      <RouletteBody
        start={start}
        set_show_reset={set_show_reset}
        set_forbid_start={set_forbid_start}
        on_complete={(prize) => {
          start_callback(prize);
        }}
        {...props}
      />
      <div
        onClick={clickToTrigger}
        className={`cursor-pointer mb-0 mx-auto text-center ${
          forbid_start ? "pointer-events-none" : ""
        }`}
      >
        <Button label={start_text} />
      </div>
      {has_reset && show_reset && (
        <div
          onClick={clickToReset}
          className={`cursor-pointer mb-0 mx-auto mt-[12%] bg-[#00000066] px-5 text-center text-[7vw] text-white border-2 border-white rounded-[5px] font-bold ${
            forbid_start ? "pointer-events-none" : ""
          }`}
        >
          {reset_text}
        </div>
      )}
    </div>
  );
};

const RouletteBody = ({
  start,
  prize_arr,
  set_show_reset,
  set_forbid_start,
  on_complete = () => {},
}) => {
  const [highlightAnimation, setHighlightAnimation] = useState(null);
  const UBoardRef = useRef(null);
  const OBoardRef = useRef(null);
  const HighlightRef = useRef(null);
  const prize_num = generate_random_number(prize_arr);

  const trigger_roulette = () => {
    TweenLite.to([UBoardRef.current, OBoardRef.current], 3, {
      rotation: 360 * 10,
      ease: Power2.easeIn,
      onComplete: () => {
        TweenLite.to([UBoardRef.current, OBoardRef.current], 3, {
          rotation: 360 * 15 + (360 * prize_num) / prize_arr.length,
          ease: Power2.easeOut,
          onComplete: () => {
            setHighlightAnimation(
              TweenMax.to(HighlightRef.current, 0.5, {
                alpha: 1,
                yoyo: true,
                repeat: -1,
              })
            );
            highlightAnimation.play();
            set_show_reset(true);
            on_complete(prize_arr[prize_num]);
          },
        });
      },
    });
  };

  const reset_roulette = () => {
    TweenLite.to([UBoardRef.current, OBoardRef.current], 1, {
      rotation: 0,
      ease: Power2.easeIn,
      onComplete: () => {
        set_forbid_start(false);
      },
    });
    setHighlightAnimation(
      TweenMax.to(HighlightRef.current, 0.5, {
        opacity: 0,
      })
    );
    if (highlightAnimation) {
      highlightAnimation.pause();
    }
  };

  useEffect(() => {
    if (start) {
      trigger_roulette();
    } else {
      reset_roulette();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start]);

  return (
    <div className="bg-roulette bg-contain bg-no-repeat p-8">
      <div className="flex relative w-full pb-[100%]">
        <div
          ref={UBoardRef}
          className="bg-roulette-under-highlight w-full pb-[100%] bg-center bg-full bg-no-repeat absolute top-0 left-0"
        />
        <div
          ref={HighlightRef}
          className="bg-highlight w-full pb-[100%] bg-center bg-full bg-no-repeat absolute top-0 left-0"
        />
        <div
          ref={OBoardRef}
          className="bg-roulette-on-highligh w-full pb-[100%] bg-center bg-full bg-no-repeat absolute top-0 left-0"
        />
      </div>
    </div>
  );
};

const generate_random_number = (prize_arr = []) => {
  if (prize_arr.length === 0) {
    return 0;
  }
  const cantidadDisponibles = _.map(prize_arr, "currentQty");
  let todosLosPremios = [];
  for (let index = 0; index < cantidadDisponibles.length; index++) {
    todosLosPremios = todosLosPremios.concat(
      Array(cantidadDisponibles[index]).fill(index)
    );
  }
  todosLosPremios = _.shuffle(todosLosPremios);
  return todosLosPremios[0];
};

export default Roulette;
