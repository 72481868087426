import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "../Home";
import Form from "../Form";
import Roullete from "../Roullete";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/form",
    element: <Form />,
  },
  {
    path: "/roullete/:playerId",
    element: <Roullete />,
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}
