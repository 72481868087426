export default function Modal({ children, onClose }) {
  return (
    <div
      className="fixed top-0 left-0 h-screen w-screen bg-[#0000009c] z-50"
      onClick={() => {
        onClose();
      }}
    >
      <div className="w-[50vw] bg-[#fff] fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 rounded-3xl p-4">
        {children}
      </div>
    </div>
  );
}
