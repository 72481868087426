import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/spinner";
import Button from "../../components/button";

export default function Home() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [otherBusinessType, setOtherBusinessType] = useState("");
  const [receiveInfo, setReceiveInfo] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const sendForm = async () => {
    if (name === "" || email === "" || phone === "" || businessType === "" || (businessType === "Otro" && otherBusinessType === "")) {
      setError("Tenés que completar todos los campos.");
    } else {
      setError("");
      try {
        setLoading(true);
        const players = localStorage.getItem("players");
        if (players) {
          const playersArray = JSON.parse(players);
          const newPlayer = {
            id: playersArray.length + 1,
            name,
            email,
            phone,
            businessType,
            otherBusinessType,
            receiveInfo,
          };
          localStorage.setItem(
            "players",
            JSON.stringify([...playersArray, newPlayer])
          );
          return navigate(`/roullete/${newPlayer.id}`);
        } else {
          localStorage.setItem(
            "players",
            JSON.stringify([
              {
                id: 1,
                name,
                email,
                phone,
                businessType,
                otherBusinessType,
                receiveInfo,
              },
            ])
          );
          return navigate("/roullete/1");
        }
      } catch (error) {
        setError("Ocurrió un error al guardar los datos. Intente de nuevo.");
        setLoading(false);
      }
    }
  };

  return (
    <div className="min-h-screen bg-fondoPrincipal bg-cover bg-no-repeat flex items-center justify-center landscape:px-8 gap-4">
      {loading && <Spinner />}
      <div className="portrait: hidden" />
      <div className="bg-fondoMadera bg-cover bg-no-repeat bg-center portrait:w-full landscape:w-3/5 flex flex-col min-h-screen justify-center items-center portrait:px-4 landscape:px-8">
        <div className="text-[35px] mb-4 font-bold text-center w-full">
          Ingresá tus datos para participar:
        </div>
        <div className="portrait:px-8 landscape:w-3/5">
          <div>
            <label className="text-[25px] font-bold">
              Nombre completo
            </label>
            <input
              placeholder="Ingresá tu nombre completo"
              className="my-4 h-10 w-full border-none bg-white rounded-md portrait:text-lg landscape:portrait:text-lg landscape:text-2xl px-4 placeholder:font-bold"
              value={name}
              onChange={(e) => {
                setError("");
                setName(e.target.value);
              }}
            />
          </div>
          <div>
            <label className="text-[25px] font-bold">E-mail</label>
            <input
              placeholder="Ingresá tu email"
              className="my-4 h-10 w-full border-none bg-white rounded-md portrait:text-lg landscape:text-2xl px-4 placeholder:font-bold"
              value={email}
              onChange={(e) => {
                setError("");
                setEmail(e.target.value);
              }}
            />
          </div>
          <div>
            <label className="text-[25px] font-bold">
              Teléfono*
            </label>
            <input
              placeholder="Ingresá tu teléfono"
              className="my-4 h-10 w-full border-none bg-white rounded-md portrait:text-lg landscape:text-2xl px-4 placeholder:font-bold"
              value={phone}
              type="number"
              min={11111111}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                setError("");
                setPhone(e.target.value);
              }}
            />
          </div>
          <div>
            <label className="text-[25px] font-bold">Tipo de negocio</label>
            <select
              className="my-4 h-10 w-full border-none bg-white rounded-md portrait:text-lg landscape:text-2xl px-4 placeholder:font-bold"
              value={businessType}
              onChange={(e) => {
                setError("");
                setBusinessType(e.target.value);
              }}
            >
              <option value="">Seleccioná una opción</option>
              <option value="Soda">Soda</option>
              <option value="Cafetería">Cafetería</option>
              <option value="Hotel">Hotel</option>
              <option value="Restaurante">Restaurante</option>
              <option value="Otro">Otro</option>
            </select>
          </div>
          {
            businessType && businessType === "Otro" && (
              <div>
                <label className="text-[25px] font-bold">Favor especifique el tipo de negocio</label>
                <input
                  placeholder="Ingresá tu tipo de negocio"
                  className="my-4 h-10 w-full border-none bg-white rounded-md portrait:text-lg landscape:text-2xl px-4 placeholder:font-bold"
                  value={otherBusinessType}
                  onChange={(e) => {
                    setError("");
                    setOtherBusinessType(e.target.value);
                  }}
                />
              </div>
            )
          }
          <div className="flex items-center gap-4 mt-4">
            <input
              type="checkbox"
              className="my-4 h-8 w-8 border-none bg-white rounded-lg portrait:text-lg landscape:text-2xl px-4 placeholder:font-bold"
              checked={receiveInfo}
              onChange={(e) => {
                setError("");
                setReceiveInfo(e.target.checked);
              }}
            />
            <label className="text-[20px] font-bold">
              Me gustaría recibir información de productos y promociones.
            </label>
          </div>
        </div>
        <div className="mt-8 flex flex-col justify-center items-center gap-12">
          {error && (
            <div className="bg-white px-4 py-2 text-primary text-center font-din-black-alternate text-xl rounded-md">
              {error}
            </div>
          )}
          <div className="mb-8">
            <Button onClick={sendForm} label={"Enviar"} />
          </div>
        </div>
      </div>
      <div className="portrait: hidden" />
    </div>
  );
}
