export default function Button({ label, ...rest }) {
  return (
    <button
      {...rest}
      className="bg-primary px-16 py-2 text-3xl text-white rounded-3xl font-bold"
    >
      {label}
    </button>
  );
}
